import cn from 'classnames';
import { onElementBlur } from '../utils/onElementBlur';
import useBooleanState from '../utils/useBooleanState';
import { Favorites } from './Favorites';
import Logout from './Logout';
import MiniCart from './MiniCart';
import MyPageLink from './MyPageLink';
import PrimaryNavigationTop from './PrimaryNavigationTop';

export default function SecondaryNavigation() {
    const [isOpen, setIsOpen] = useBooleanState();

    return (
        <div className="secondary-navigation">
            <MyPageLink />
            <Favorites />
            <MiniCart />
            <div className="hamburger-menu" onBlur={onElementBlur(setIsOpen.toFalse)}>
                <button
                    onClick={setIsOpen.toggle}
                    aria-controls="header-mobile-menu"
                    aria-expanded={isOpen}
                    aria-label={isOpen ? 'Steng menyen' : 'Åpne menyen'}
                >
                    <span className={cn('hamburger-menu__icon', { 'hamburger-menu__icon--open': isOpen })} aria-hidden="true">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                    </span>
                </button>
                {isOpen && (
                    <div className="header-mobile-menu" id="header-mobile-menu">
                        <div className="secondary-navigation-items-mobile">
                            <MyPageLink />
                            <Favorites />
                        </div>
                        <PrimaryNavigationTop />
                        <Logout />
                    </div>
                )}
            </div>
        </div>
    );
}
