import cn from 'classnames';
import { ButtonHTMLAttributes, DetailedHTMLProps, PropsWithChildren } from 'react';

export interface Props extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    isLoading: boolean;
}

export default function SubmitButton({ isLoading, children, className, ...props }: PropsWithChildren<Props>) {
    return (
        <button type="submit" className={cn(className ?? 'btn-primary', { 'btn-spinner': isLoading })} disabled={isLoading} {...props}>
            {children}
        </button>
    );
}
