export default function skyra() {
    const script = document.createElement('script');
    script.src = 'https://survey.skyra.no/skyra-survey.js';
    script.onload = function () {
        window.skyra.start({
            org: 'vinmonopolet',
        });
    };
    document.body.appendChild(script);
}
