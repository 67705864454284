import { PropsWithChildren, createContext, useState } from 'react';
import { User } from '../model/types';
import { login, logout, onUserChange, refreshUser } from '../utils/auth';
import useComponentDidMount from '../utils/useComponentDidMount';
import LoginNeededModal from './LoginNeededModal';

interface UserContextFunctions {
    login(username: string, password: string): Promise<void>;
    refreshUser(): Promise<void>;
    logout(): Promise<void>;
}

interface PendingUser extends UserContextFunctions {
    isLoggedIn: undefined;
    user: undefined;
    showLoginModal(): undefined;
}

export interface LoggedInUser extends UserContextFunctions {
    isLoggedIn: true;
    user: Readonly<User>;
    showLoginModal(): false;
}

export interface AnonymousUser extends UserContextFunctions {
    isLoggedIn: false;
    user: null;
    showLoginModal(): true;
}

export type UserContext = Readonly<LoggedInUser | AnonymousUser | PendingUser>;

export const defaultUserContext: UserContext = {
    isLoggedIn: undefined,
    user: undefined,
    showLoginModal() {
        return undefined;
    },
    refreshUser: () => Promise.resolve(),
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
};

export const UserContext = createContext<UserContext>(defaultUserContext);

export function UserContextProvider({ children }: PropsWithChildren) {
    const userContext: UserContext = getUserContext();

    return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>;
}

const anonymousUser: AnonymousUser = {
    isLoggedIn: false,
    user: null,
    login,
    logout,
    refreshUser,
    showLoginModal() {
        gtag('event', 'modal_view', {
            modal_name: 'LoginNeeded',
        });
        LoginNeededModal.call();
        return true;
    },
};

const pendingUser: PendingUser = {
    isLoggedIn: undefined,
    user: undefined,
    login,
    logout,
    refreshUser,
    showLoginModal() {
        return undefined;
    },
};

function getUserContext(): UserContext {
    const user = getUser();

    switch (user) {
        case null:
            return anonymousUser;
        case undefined:
            return pendingUser;
        default:
            return {
                isLoggedIn: true,
                user,
                login,
                logout,
                refreshUser,
                showLoginModal() {
                    return false;
                },
            };
    }
}

function getUser() {
    const [user, setUser] = useState<User | null | undefined>(undefined);

    useComponentDidMount(() => onUserChange(setUser));

    return user;
}
