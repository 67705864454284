import cn from 'classnames';
import { UIEvent, useEffect, useRef, useState } from 'react';
import { getArray } from '../cms';
import { CmsComponent } from '../cms/CMSComponent';
import getHeaderData, { getOtherProperty } from '../cms/getHeaderData';
import { onElementBlur } from '../utils/onElementBlur';
import preventDefault from '../utils/preventDefault';
import { trackHeaderLinkClick } from '../utils/trackLinkClick';
import useBooleanState from '../utils/useBooleanState';

interface Link {
    uid?: string;
    url?: string;
    name?: string;
    isDivider?: boolean;
}

const PendingLinks = [{ uid: '0' }];
export default function HeaderCategoryBar() {
    //placeholder data while loading
    const [links, setLinks] = useState<Link[]>(PendingLinks);

    useEffect(() => {
        getHeaderData('NavigationBarSlot')
            .then((components) =>
                components
                    .flatMap((c) => getArray(c.components))
                    .flatMap((c) => c.navigationNode?.children)
                    .flatMap((c) => c?.links)
                    .filter(Boolean)
                    .map(toLink),
            )
            .then(setLinks);
    }, []);

    const box = useRef<HTMLUListElement>(null);
    const [maxHeight, setMaxHeight] = useState(0);
    const [open, setOpen] = useBooleanState();

    useEffect(() => {
        if (!open) return;

        function handler() {
            if (!box.current) return;

            const { top } = box.current?.getBoundingClientRect();
            setMaxHeight(window.innerHeight - top);
        }

        handler();

        window.addEventListener('resize', handler);
        return () => window.removeEventListener('resize', handler);
    }, [open]);

    const hidden = useScrollHiding() && !open;

    return (
        <div className={cn('header-category-bar', { 'scroll-hidden': hidden })}>
            <div className="section__inner-container">
                <nav className="primary-navigation" id="primary-navigation" tabIndex={-1}>
                    <ul>
                        {/*######### Fremhevede varegrupper #########*/}
                        {links.slice(0, 10).map(({ uid, url, name }) => (
                            <li key={uid} className="header-category-bar-item">
                                <a href={url} rel="nofollow" onClick={trackHeaderLinkClick}>
                                    {name}
                                </a>
                            </li>
                        ))}
                        {/*######### Varegruppe popup - de som ikke er fremhevet #########*/}
                        <li className="see-more" onBlur={onElementBlur(setOpen.toFalse)}>
                            <a
                                className="navigation-toggler"
                                onClick={preventDefault(setOpen.toggle, trackHeaderLinkClick)}
                                aria-expanded={open}
                                aria-controls="header-category-bar-see-more"
                                href="#"
                            >
                                Se flere
                            </a>
                            <ul id="header-category-bar-see-more" onScroll={preventPageScroll} style={{ maxHeight }} ref={box}>
                                {links.map(({ isDivider, name, uid, url }) =>
                                    isDivider ? (
                                        <li key={uid} className="header-category-bar-see-more-item divider" />
                                    ) : (
                                        <li key={uid} className="header-category-bar-see-more-item">
                                            <a href={url} rel="nofollow" onClick={trackHeaderLinkClick}>
                                                {name}
                                            </a>
                                        </li>
                                    ),
                                )}
                            </ul>
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}

function toLink(childLink: CmsComponent) {
    const name = getOtherProperty<string>(childLink, 'linkName');
    const url = getOtherProperty<string>(childLink, 'url');
    return {
        name,
        url,
        uid: childLink.uid,
        isDivider: name === 'divider',
    };
}

function useScrollHiding() {
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        let position = window.scrollY;

        const handler = () => {
            const scroll = window.scrollY;

            if (scroll < position) {
                setHidden(false);
            } else if (scroll >= 200) {
                setHidden(true);
            }
            position = scroll;
        };

        document.addEventListener('scroll', handler, { passive: true });

        return () => document.removeEventListener('scroll', handler);
    }, []);

    return hidden;
}

function preventPageScroll(e: UIEvent) {
    if (e.currentTarget.scrollTop < 1) {
        e.currentTarget.scrollTop = 1;
    }
    if (e.currentTarget.scrollHeight - e.currentTarget.scrollTop - e.currentTarget.clientHeight < 1) {
        e.currentTarget.scrollTop = e.currentTarget.scrollTop - 1;
    }
}
