import cn from 'classnames';
import { PropsWithChildren } from 'react';
import { useScrollLock } from 'usehooks-ts';
import preventDefault from '../utils/preventDefault';
import Icon from './Icon';
import * as style from './Modal.module.less';

export interface Props {
    isOpen?: boolean;
    onClose?: () => void;
    padding?: boolean;
    size?: 'small' | 'medium' | 'large';
}

export default function Modal({ isOpen = false, size = 'medium', padding = false, onClose, children }: PropsWithChildren<Props>) {
    if (!isOpen) return;

    return (
        <dialog
            ref={(e) => e?.showModal()}
            className={cn(style.dialog, style[size])}
            onCancel={preventDefault(onClose)}
            onClick={(e) => e.target === e.currentTarget && onClose?.()}
        >
            <ScrollLock />
            <div className={cn(style.dialogContent, { [style.noPadding]: !padding })}>
                {!!onClose && (
                    <button className={style.dialogClose} onClick={onClose} aria-label="Lukk">
                        <Icon className="icon-xmark" />
                    </button>
                )}
                {children}
            </div>
        </dialog>
    );
}

function ScrollLock() {
    useScrollLock();

    return null;
}
