import { NotFoundError, UnauthorizedError } from '../api';

export function catchNotFoundAndRetry<A extends any[], R>(originalMethod: (...args: A) => Promise<R>) {
    return catchAndRetry(originalMethod, (e) => e instanceof NotFoundError);
}

export function catchUnauthorizedAndRetry<A extends any[], R>(originalMethod: (...args: A) => Promise<R>) {
    return catchAndRetry(originalMethod, (e) => e instanceof UnauthorizedError);
}

export function catchAndRetry<A extends any[], R>(originalMethod: (...args: A) => Promise<R>, when: (e: unknown) => boolean) {
    return async (...args: A): Promise<R> => {
        try {
            return await originalMethod(...args);
        } catch (e: unknown) {
            if (when(e)) {
                return await originalMethod(...args);
            } else {
                throw e;
            }
        }
    };
}
