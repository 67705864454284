import { createCallable } from 'react-call';
import { loginURLWithRedirect } from '../config/SiteConfig';
import Modal from '../generic/Modal';
import OkCancelButtons from '../generic/OkCancelButtons';

const LoggedOutModal = createCallable(() => (
    <Modal isOpen={true} padding size="small">
        <h3>Du har blitt logget ut</h3>
        <p>Du har blitt logget ut, logg inn for å fortsette.</p>
        <OkCancelButtons
            okButtonText="Gå til innlogging"
            onOK={loginURLWithRedirect()}
            cancelButtonText="Fortsett uten å logge inn"
            onCancel={() => location.reload()}
            centered
        />
    </Modal>
));

export default LoggedOutModal;
