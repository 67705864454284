import cn from 'classnames';
import { MouseEvent, useState } from 'react';
import { useMediaQuery, useToggle } from 'usehooks-ts';
import { CmsComponent, CmsNavigationNodeEntry } from '../cms/CMSComponent';
import getHeaderData, { getOtherProperty } from '../cms/getHeaderData';
import salesforceChat from '../layout/salesforceChat';
import { useOnMount } from '../utils/useComponentDidMount';

interface ContactUs {
    title?: string;
}

interface State {
    contactUs?: ContactUs;
    extended?: FooterGroup;
    items: FooterGroup[];
}

interface Link {
    uid: string;
    title?: string;
    url?: string;
}

interface FooterGroup {
    uid: string;
    title?: string;
    links: Link[];
}

export default function Footer() {
    const [items, setItems] = useState<FooterGroup[]>([]);
    const [contactUs, setContactUs] = useState<ContactUs | undefined>();
    const [extended, setExtended] = useState<FooterGroup | undefined>();

    useOnMount(async ({ user, isLoggedIn }) => {
        const nodes = await getHeaderData('FooterSlot');
        const { items, extended, contactUs } = toState(nodes.flatMap((c) => c.navigationNodes ?? []) ?? []);
        salesforceChat(isLoggedIn, user);
        setItems(items);
        setExtended(extended);
        setContactUs(contactUs);
    });

    return (
        <>
            <div className="section-footer-links" onClick={trackLinkClick}>
                <div className="section__inner-container">
                    <div className="footer-links">
                        {items.map(({ uid, title, links }, index) => (
                            <FooterGroup key={`${uid}${index}`} uid={uid} title={title} links={links} />
                        ))}
                    </div>
                </div>
            </div>
            <div className="section-footer-contact" onClick={trackLinkClick}>
                <div className="section__inner-container">
                    {contactUs && <ContactUs {...contactUs} />}
                    {extended && <Extended {...extended} />}
                </div>
            </div>
        </>
    );
}

function ContactUs({ title }: ContactUs) {
    return (
        <div className="footer-contact">
            <div className="footer__section">
                <h2 className="footer__headline">{title}</h2>
                <div className="footer-items">
                    <div className="footer-opening-hours">
                        <b>Åpningstider:</b>
                        <span>Mandag, tirsdag, torsdag og fredag:</span>
                        <span>08:00 - 18:00</span>
                        <span>Onsdag:</span>
                        <span>09:00 - 18:00</span>
                        <span>Lørdag:</span>
                        <span>10:00 - 16:00</span>
                    </div>
                    <ul id="ContactUsNavNode" className="list-unstyled">
                        <li className="CallUsLink">
                            <a href="tel:22015000" className="footer-icon-button">
                                22 01 50 00
                            </a>
                        </li>
                        <li className="SendUsEmailLink">
                            <a href="mailto:kundesenter@vinmonopolet.no" className="footer-icon-button">
                                kundesenter@vinmonopolet.no
                            </a>
                        </li>
                    </ul>
                    <ul id="footer-contact-links" className="list-unstyled">
                        <li className="VmpOnFacebookLink">
                            <a href="https://www.facebook.com/Vinmonopolet" className="footer-icon-link">
                                Facebook
                            </a>
                        </li>
                        <li className="VmpOnLinkedInLink">
                            <a href="https://no.linkedin.com/company/vinmonopolet" className="footer-icon-link">
                                LinkedIn
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

function FooterGroup({ uid, title, links }: FooterGroup) {
    const [show, toggle] = useToggle();
    const isDesktop = useMediaQuery('(min-width: 1024px)');

    const expanded = show || isDesktop;

    return (
        <div className="navigation-toggler-container">
            <h2 className="navigation-toggler navigation-toggler-title" aria-label={title}>
                {title}
            </h2>
            <button className="btn navigation-toggler navigation-toggler-btn" onClick={toggle} aria-expanded={expanded} aria-controls={uid}>
                {title}
            </button>
            <ul
                id={uid}
                className={cn('navigation-content-footer', 'list-unstyled', { 'navigation-content-footer--open': expanded })}
                aria-hidden={!expanded}
            >
                {links.map(({ uid, title, url }, index) => (
                    <li className={uid} key={`${uid}${index}`}>
                        <a href={url} className="link--no-border">
                            {title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function Extended({ title = '', links }: FooterGroup) {
    return (
        <div className="extended-footer">
            <div className="extended-footer-title" dangerouslySetInnerHTML={{ __html: title }} />
            <ul className="list-unstyled">
                {links.map((link) => (
                    <li key={link.uid}>
                        <a href={link.url} title={link.title} className="footer-button">
                            {link.title}
                        </a>
                    </li>
                ))}
            </ul>
        </div>
    );
}

function toState(nodes: CmsNavigationNodeEntry[]): State {
    const contactUs = nodes.find((n) => n.uid === 'ContactUsNavNode');
    const extended = nodes.find((n) => n.uid === 'ExtendedFooter');

    return {
        contactUs: contactUs && toContactUs(contactUs),
        extended: extended && toFooterGroup(extended),
        items: nodes.filter((n) => n !== contactUs && n !== extended).map(toFooterGroup),
    };
}

function toFooterGroup(node: CmsNavigationNodeEntry) {
    return {
        uid: node.uid,
        title: node.title,
        links: node.links?.map(toLink) ?? [],
    };
}

function toContactUs(contactUs: CmsNavigationNodeEntry): ContactUs {
    return {
        title: contactUs.title,
    };
}

function toLink(l: CmsComponent) {
    return {
        uid: l.uid,
        url: getOtherProperty<string>(l, 'url'),
        title: getOtherProperty<string>(l, 'linkName'),
    };
}

function trackLinkClick(event: MouseEvent): void {
    if (event.target instanceof HTMLAnchorElement) {
        gtag('event', 'footer_link_click', {
            link_text: event.target.textContent,
            link_url: event.target.href,
        });
    }
}
