import * as api from '.';
import { AddressT } from '../account/Address';
import { CartSummaryData } from '../cart/CartSummary';
import { cartEntriesURL, cartEntryURL, cartURL, minicartURL } from '../config/CartAPIConfig';
import { FormattedPrice } from '../model/types';
import { CardPaymentInfo } from './payment';

export interface CartResponse extends CartSummaryData {
    deliveryAddress?: AddressT;
    totalUnitCount: number;
    deliveryError?: string;
    error?: { message: string; code: string };
    broadcastMessage?: string;
    greetingCardAvailable: boolean;
    greetingCardFrom?: string;
    greetingCardTo?: string;
    greetingCardMessage?: string;
    greetingCardCost: FormattedPrice;
    paymentMode: PaymentMode;
    paymentInfo?: CardPaymentInfo;
    paymentSaveCard?: boolean;
    paymentError?: {
        message: string;
    };
    restAmount: FormattedPrice;
    payableByGiftCard: boolean;
}

export type PaymentMode = null | 'Vipps' | 'NewCard' | 'PaymentCard';

export async function getCart(userId: string, cartId: string) {
    return await api.get<CartResponse>(cartURL(userId, cartId));
}

export interface MiniCartResponse {
    totalUnitCount: number;
    subTotal: FormattedPrice;
    entries: {
        entryNumber: number;
        quantity: number;
        basePrice: FormattedPrice;
        product: {
            code: string;
        };
    }[];
}

export async function getMiniCart(userId: string, cartId: string, reload = false) {
    return await api.get<MiniCartResponse>(minicartURL(userId, cartId), undefined, { cache: reload ? 'reload' : 'default' });
}

export interface UpdateCartEntryResponse {
    entry: {
        product: {
            code: string;
        };
    };
    quantityAdded: number;
    quantity: number;
}

export async function addCartEntry(userId: string, cartId: string, code: string, qty = 1, price?: number) {
    return await api.post<UpdateCartEntryResponse>(cartEntriesURL(userId, cartId), price ? { code, qty, price } : { code, qty });
}

export async function updateCartEntry(userId: string, cartId: string, entryNumber: number, quantity: number) {
    return await api.patch<UpdateCartEntryResponse>(`${cartEntryURL(userId, cartId, entryNumber)}?qty=${quantity}`);
}

export async function deleteCartEntry(userId: string, cartId: string, entryNumber: number) {
    return await api.delete<UpdateCartEntryResponse>(cartEntryURL(userId, cartId, entryNumber));
}
