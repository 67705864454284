import { EffectCallback, useContext, useEffect, useRef } from 'react';
import { AnonymousUser, LoggedInUser, UserContext } from '../login/userContext';

export default function useComponentDidMount(effect: EffectCallback) {
    useEffect(effect, []);
}

/**
 * Calls the callback on mount, after the user context has settled
 */
export function useOnMount(callback: (user: Readonly<LoggedInUser | AnonymousUser>) => void) {
    const userContext = useContext(UserContext);
    const wasUndefined = useRef(true);

    useEffect(() => {
        if (userContext.isLoggedIn !== undefined && wasUndefined.current) {
            wasUndefined.current = false;
            callback(userContext);
        }
    }, [userContext.isLoggedIn]);
}
