import cn from 'classnames';
import { PropsWithChildren } from 'react';
import Icon from './Icon';

export interface Props {
    message?: string;
    className?: string;
    background?: string;
}

export default function InfoBox({ message, className, background, children = message }: PropsWithChildren<Props>) {
    if (!children) return null;

    return (
        <div className={cn('info-message', className)} aria-live="assertive">
            <Icon className={cn('icon-alert-info', background)}/>
            <div className="message-text">{children}</div>
        </div>
    );
}
