import { User } from '../model/types';
import { loadJson, removeJson, saveJson, subscribeToJson } from './jsonStorage';

export const tokenKey = 'token';

export interface ExpiringValue {
    value: string;
    expiresAt: number;
}

export interface Token {
    user: User | null;
    accessToken: ExpiringValue;
    refreshToken: ExpiringValue;
    deviceId: string;
}

let currentToken: Token | null;

if (typeof window !== 'undefined') {
    // make sure `currentToken` updates when it is changed
    // in another tab. This prevents many logged out bugs!
    onTokenChange((token) => {
        currentToken = token;
    });
}

export function getToken(): Token | null {
    if (!currentToken) {
        currentToken = loadJson(tokenKey);
    }
    return currentToken;
}

export function setToken(accessToken: ExpiringValue, refreshToken: ExpiringValue, deviceId: string) {
    currentToken = {
        user: currentToken?.user ?? null,
        accessToken,
        refreshToken,
        deviceId,
    };

    saveJson(tokenKey, currentToken);

    return currentToken;
}

export function setUser(user: User) {
    if (!currentToken) throw new Error('Cannot set user without token');

    currentToken = {
        ...currentToken,
        user,
    };

    saveJson(tokenKey, currentToken);
}

export function onTokenChange(onTokenChange: (token: Token | null) => void) {
    return subscribeToJson<Token>(tokenKey, onTokenChange);
}

export function clearToken() {
    currentToken = null;
    removeJson(tokenKey);
}
