import { PropsWithChildren } from 'react';
import { CartContextProvider } from '../cart/CartContext';
import { UserContextProvider } from '../login/userContext';

export default function GlobalContextProvider({ children }: PropsWithChildren) {
    return (
        <UserContextProvider>
            <CartContextProvider>{children}</CartContextProvider>
        </UserContextProvider>
    );
}
