export function loadJson<T>(key: string) {
  if (typeof window === 'undefined') return null;

  const item = localStorage.getItem(key);
  if (item === null) return null;
  try {
    return JSON.parse(item) as T;
  } catch {
    return null;
  }
}

export function subscribeToJson<T>(key: string, handler: (value: T | null) => void) {
  if (typeof window === 'undefined') return () => undefined;

  handler(loadJson(key));
  function eventHandler(e: StorageEvent) {
    if (e.storageArea === localStorage && e.key === key) {
      handler(loadJson(key));
    }
  }
  window.addEventListener('storage', eventHandler);
  return () => window.removeEventListener('storage', eventHandler);
}

export function removeJson(key: string) {
  if (typeof window === 'undefined') return;

  if (localStorage.getItem(key)) {
    localStorage.removeItem(key);
    window.dispatchEvent(new StorageEvent('storage', { key, newValue: null, storageArea: localStorage }));
  }
}

export function saveJson(key: string, value: any) {
  if (typeof window === 'undefined') return;

  localStorage.setItem(key, JSON.stringify(value));
  window.dispatchEvent(new StorageEvent('storage', { key, newValue: value, storageArea: localStorage }));
}