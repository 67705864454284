import * as api from '../api';
import { cmsAPI } from '../config';
import { CmsComponent } from './CMSComponent';
import { CmsPage } from './PageData';
import { getArray } from './index';

export function getOtherProperty<T extends string | boolean>(component: CmsComponent, key: string): T | undefined {
    return component.otherProperties?.find((e) => e?.key === key)?.value?.value as any;
}

let promise: Promise<CmsPage> | null = null;
function getData() {
    const params: Record<string, string> = {
        fields: 'DEFAULT',
    };
    const cmsTicketId = new URL(window.location.href).searchParams.get('cmsTicketId');
    if (cmsTicketId) {
        params['cmsTicketId'] = cmsTicketId;
    }
    return promise ?? (promise = api.getAnonymously<CmsPage>(cmsAPI.headerAndFooterURL, params));
}

export default async function getHeaderData(slotId: string) {
    const data = await getData();
    const slotList = data.contentSlots?.contentSlots?.find((element) => slotId === element?.slotId)?.components;
    return getArray(slotList?.components);
}
