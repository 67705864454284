function baseUrl(userId = 'current', version = 2) {
    return `/vmpws/v${version}/vmp/users/${userId}`;
}

export function cartsURL(userId: string, version = 2) {
    return `${baseUrl(userId, version)}/carts`;
}

export function currentUsersCartURL() {
    return cartsURL('current');
}

export function cartURL(userId = 'current', cartId = 'current', version = 2) {
    return `${cartsURL(userId, version)}/${cartId}`;
}

export function minicartURL(userId: string, cartId: string) {
    return `${cartURL(userId, cartId)}/minicart`;
}

export function deliveryModesURL(userId: string, cartId: string) {
    return `${cartURL(userId, cartId)}/deliverymodes`;
}

export function cartEntriesURL(userId: string, cartId: string) {
    return `${cartURL(userId, cartId)}/entries`;
}

export function cartEntryURL(userId: string, cartId: string, entryNumber: number) {
    return `${cartEntriesURL(userId, cartId)}/${entryNumber}`;
}

export function cartDeliveryModeURL(userId: string, cartId: string, mode: string) {
    return `${cartURL(userId, cartId)}/deliverymode/${mode}`;
}

export function greetingCardURL(userId: string, cartId: string) {
    return `${cartURL(userId, cartId)}/greeting`;
}

export function deliveryAddressURL(userId: string, cartId: string, addressId: string) {
    return `${cartURL(userId, cartId)}/deliveryaddress/${addressId}`;
}

export function pointOfServiceURL(userId: string, cartId: string, storeId: string) {
    return `${cartURL(userId, cartId)}/pointofservice/${storeId}`;
}

export function listToCartURL(userId: string, cartId: string, listId: string) {
    return `${cartURL(userId, cartId)}/wishlist-to-cart/${listId}`;
}

export function giftCardURL() {
    return `${cartURL()}/giftcard`;
}

export function paymentDetailsURL() {
    return `${cartURL()}/paymentdetails`;
}

export function ordersURL() {
    return `${baseUrl()}/orders`;
}

export function orderStatusURL() {
    return `${ordersURL()}/status`;
}

export function processPaymentURL() {
    return `${ordersURL()}/processpayment`;
}

export function orderURL(orderCode: string) {
    return `${ordersURL()}/${orderCode}`;
}
