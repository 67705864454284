import { wishlistURL } from '../config/SiteConfig';

export function Favorites() {
    return (
        <div className="secondary-navigation__item">
            <a className="secondary-navigation__link favourites__link" href={wishlistURL()} rel="nofollow">
                <span className="secondary-navigation__link-icon"></span>
                <span className="secondary-navigation__link-name">Mine lister</span>
            </a>
        </div>
    );
}
