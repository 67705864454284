import { useContext, useEffect, useRef } from 'react';
import { CartContext, MiniCartContext } from '../cart/CartContext';
import { cartURL } from '../config/SiteConfig';
import { useOnMount } from '../utils/useComponentDidMount';

export default function MiniCart() {
    const cart = useContext(CartContext);
    const miniCart = useContext(MiniCartContext);
    const cartCountRef = useRef<HTMLElement>(null);

    useOnMount(() => {
        cart.fetchMiniCart(shouldForceReloadCart());

        document.addEventListener('visibilitychange', () => {
            if (document.visibilityState === 'visible') {
                cart.fetchMiniCart();
            }
        });
    });

    const prevCount = useRef(miniCart?.totalUnitCount);
    useEffect(() => {
        if (miniCart?.totalUnitCount && prevCount.current !== undefined) runAnimation(cartCountRef.current);
        prevCount.current = miniCart?.totalUnitCount;
    }, [miniCart?.totalUnitCount]);

    const itemCount = miniCart?.totalUnitCount ?? 0;

    return (
        <div className="secondary-navigation__item">
            <a href={cartURL()} rel="nofollow" className="secondary-navigation__link cart__link" aria-label={`Handlekurv, ${itemCount} varer`}>
                <span className="secondary-navigation__link-icon cart__icon" aria-live="off">
                    <span ref={cartCountRef} className="cart__count" data-count={itemCount}>
                        {itemCount}
                    </span>
                </span>
                <span className="secondary-navigation__link-name">Handlekurv</span>
            </a>
        </div>
    );
}
function runAnimation(element: HTMLElement | null) {
    element?.animate(
        [
            // keyframes
            { transform: 'scale(2)' },
        ],
        {
            // timing options
            easing: 'ease-in-out',
            duration: 300,
            iterations: 1,
        }
    );
}

function shouldForceReloadCart() {
    if (location.pathname.startsWith('/cart')) return true;
    if (location.pathname.startsWith('/checkout')) return true;
    return false;
}
