import { MouseEvent } from 'react';
import { createCallable } from 'react-call';
import { loginURLWithRedirect } from '../config/SiteConfig';
import Modal from '../generic/Modal';
import OkCancelButtons from '../generic/OkCancelButtons';

const LoginNeededModal = createCallable(({ call }) => (
    <Modal onClose={call.end} isOpen padding size="small">
        <h3>Vennligst logg inn</h3>
        <p>Denne funksjonaliteten krever at du er logget inn.</p>
        <div onClick={trackLoginClicked}>
            <OkCancelButtons onCancel={call.end} onOK={loginURLWithRedirect()} okButtonText="Gå til innlogging / registrering" centered />
        </div>
    </Modal>
));

export default LoginNeededModal;

function trackLoginClicked(e: MouseEvent<HTMLElement>) {
    if (e.target instanceof HTMLAnchorElement) {
        gtag('event', 'modal_viwe', {
            modal_name: 'LoginNeeded',
            link_text: e.target.textContent,
        });
    }
}
