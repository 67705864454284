import { MouseEvent } from 'react';

export function trackHeaderLinkClick(e: MouseEvent<HTMLAnchorElement>) {
    gtag('event', 'header_link_click', {
        link_text: e.currentTarget.textContent,
        link_url: e.currentTarget.href,
    });
}

export function trackLinkClick({ target, currentTarget }: MouseEvent<HTMLElement>) {
    if (target instanceof HTMLElement) {
        const module = currentTarget.dataset.module;
        const closest = target.closest('a, button');
        if (closest) {
            gtag('event', 'link_click', {
                link_text: closest.textContent,
                link_url: closest instanceof HTMLAnchorElement ? closest.href : undefined,
                module,
            });
        }
    }
}
