const baseURL = '/vmpws/v2/vmp/vmpcms';
export const pagesURL = baseURL + '/pages';
export const headerAndFooterURL = baseURL + '/headerAndFooter';

export function cmsDetectURL(path: string, queryString: string) {
    return '/vmpws/v3/vmp/detect/cms?' + new URLSearchParams({ path, queryString });
}

export function categoryDetectURL(id: string) {
    return `/vmpws/v3/vmp/detect/category/${id}`;
}
