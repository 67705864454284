import cn from 'classnames';
import { useEffect, useState } from 'react';
import { getArray } from '../cms';
import { CmsComponent, CmsLinkComponent } from '../cms/CMSComponent';
import getHeaderData, { getOtherProperty } from '../cms/getHeaderData';
import { onElementBlur } from '../utils/onElementBlur';
import preventDefault from '../utils/preventDefault';
import { trackHeaderLinkClick } from '../utils/trackLinkClick';
import useBooleanState from '../utils/useBooleanState';

interface Link {
    uid?: string;
    link?: CmsLinkComponent;
    children: {
        uid: string;
        links: SubLinkProps[];
    }[];
}

const PLACEHOLDER = { children: [] };

export default function PrimaryNavigationTop() {
    const [links, setLinks] = useState<Link[]>([PLACEHOLDER, PLACEHOLDER, PLACEHOLDER]);

    useEffect(() => {
        getHeaderData('TopHeaderSlot')
            .then((components) =>
                components
                    .flatMap((c) => getArray(c.components))
                    .filter(Boolean)
                    .map(toLink),
            )
            .then(setLinks);
    }, []);

    return (
        <nav className="primary-navigation">
            <ul className="primary-navigation__top-nav-items list-unstyled">
                {links.map(({ uid, children, link }, index) => (
                    <Link key={`${uid}${index}`} link={link}>
                        {children}
                    </Link>
                ))}
            </ul>
        </nav>
    );
}

interface NavigationBarComponent extends CmsComponent {
    link?: CmsLinkComponent;
}

function toLink({ uid, link, navigationNode }: NavigationBarComponent): Link {
    return {
        uid,
        link,
        children:
            navigationNode?.children?.map(({ uid, links }) => ({
                uid,
                links:
                    links?.map((l) => ({
                        uid: l.uid,
                        url: getOtherProperty<string>(l, 'url'),
                        target: getOtherProperty<boolean>(l, 'target'),
                        linkName: getOtherProperty<string>(l, 'linkName'),
                    })) ?? [],
            })) ?? [],
    };
}

function Link({ link, children }: Link) {
    const hasChildren = children.some((c) => c.links.length > 1);

    return hasChildren ? (
        <DropdownLink link={link}>{children}</DropdownLink>
    ) : link ? (
        <li className="primary-navigation__top-nav-item">
            <a href={link.url} onClick={trackHeaderLinkClick}>
                {link.linkName}
            </a>
        </li>
    ) : (
        <li className="primary-navigation__top-nav-item">
            <a className="placeholder-text">&nbsp;</a>
        </li>
    );
}

function DropdownLink({ link, children }: Link) {
    const [open, setOpen] = useBooleanState();

    return (
        <li className="primary-navigation__top-nav-item" onBlur={onElementBlur(setOpen.toFalse)}>
            <a
                href={link?.url}
                className={cn('navigation-toggler', { 'navigation-toggler--open': open })}
                onClick={preventDefault(setOpen.toggle, trackHeaderLinkClick)}
                aria-expanded={open}
                aria-controls={link?.uid}
            >
                {link?.linkName}
            </a>
            <div className="primary-navigation__top-nav-item-children" style={{ display: open ? 'block' : 'none' }}>
                {children.map(({ uid, links }) => (
                    <div key={uid} className="arrow-box-bordered">
                        <ul className="link-list">
                            {links.map(({ uid, ...props }) => (
                                <SubLink key={uid} {...props} />
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </li>
    );
}

interface SubLinkProps {
    uid?: string;
    url?: string;
    target?: boolean;
    linkName?: string;
}

function SubLink({ url, target, linkName }: SubLinkProps) {
    return (
        <li>
            <a
                href={url}
                target={target ? '_blank' : undefined}
                title={linkName}
                rel={target ? 'noreferrer' : undefined}
                onClick={trackHeaderLinkClick}
            >
                {linkName}
            </a>
        </li>
    );
}
