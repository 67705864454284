/**
 * Prevent multiple simultaneous calls to the asyncFunction.
 * All simultaneous calls will return the same promise
 */
export default function syncBlock<T>(asyncFunction: () => Promise<T>): () => Promise<T> {
  let pendingPromise: Promise<T> | null = null;

  return async () => {
    if (pendingPromise) return pendingPromise;

    try {
      pendingPromise = asyncFunction();
      return await pendingPromise;
    } finally {
      pendingPromise = null;
    }
  }
}