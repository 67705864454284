let module: undefined | Promise<void | typeof import('./sanityVisualEditing')>;
export function lazyLoadVisualEditing() {
    module ??= import('./sanityVisualEditing').then((module) => module.default());
}

export default function detectSanityPresentationMode(detected: () => void) {
    window.addEventListener('message', (e) => {
        if (!e.data || typeof e.data !== 'object') return;
        const { domain, from, to, type } = e.data;
        if (domain !== 'sanity/channels') return;
        if (from !== 'presentation') return;
        if (to !== 'overlays') return;
        if (type !== 'handshake/syn') return;
        detected();
    });
}
