import { useContext } from 'react';
import { accountURL } from '../config/SiteConfig';
import { UserContext } from '../login/userContext';

export default function MyPageLink() {
    return (
        <div className="secondary-navigation__item">
            <a className="secondary-navigation__link min-side__link" href={accountURL()} rel="nofollow">
                <span className="secondary-navigation__link-icon"></span>
                <span className="secondary-navigation__link-name">
                    <MyPageText />
                </span>
            </a>
        </div>
    );
}

function MyPageText() {
    const { isLoggedIn, user } = useContext(UserContext);

    switch (isLoggedIn) {
        case undefined:
            return <>&nbsp;</>;
        case true:
            return (
                <>
                    {user.firstName.substring(0, 25)} <span className="sr-only"> sin side</span>
                </>
            );
        case false:
            return <>Min side</>;
    }
}
