import cn from 'classnames';
import { useContext } from 'react';
import { useToggle } from 'usehooks-ts';
import { UserContext } from '../login/userContext';
import preventDefault from '../utils/preventDefault';
import useBooleanState from '../utils/useBooleanState';
import { useOnMount } from '../utils/useComponentDidMount';

export default function Logout() {
    const { logout } = useContext(UserContext);
    const [isBusy, toggleBusy] = useToggle(false);

    const onClick = async () => {
        toggleBusy();
        await logout();
        location.href = '/';
    };

    const [isLoggedIn, setIsLoggedIn] = useBooleanState();
    useOnMount(({ isLoggedIn }) => {
        setIsLoggedIn(isLoggedIn);
    });

    if (!isLoggedIn) return null;

    return (
        <div className="primary-navigation__top-nav-item primary-navigation__logout">
            <a href="/" className={cn({ 'btn-spinner': isBusy })} onClick={preventDefault(onClick)}>
                Logg ut
            </a>
        </div>
    );
}
