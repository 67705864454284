import { createCallable } from 'react-call';
import { useTimeout } from 'usehooks-ts';
import InfoBox from '../InfoBox';

export interface Props {
    message: string;
}

const Toast = createCallable<Props>(({ call, message }) => {
    useTimeout(call.end, 3000);

    return <InfoBox message={message} background="deep-sea-green" />;
});

export default Toast;
