// extracted by mini-css-extract-plugin
var _1 = "dialog-fboMnRKL";
var _2 = "dialog-close-hgrID_G7";
var _3 = "dialog-content-tjqrGT9Y";
var _4 = "dialog-close-hgrID_G7";
var _5 = "dialog-content-tjqrGT9Y";
var _6 = "large-rm7cU_P1";
var _7 = "medium-GggXpSbU";
var _8 = "no-padding-z72IUggZ";
var _9 = "no-padding-z72IUggZ";
var _a = "small-qnvRG3ug";
var _b = "white-ti8xsDnV";
export { _1 as "dialog", _2 as "dialog-close", _3 as "dialog-content", _4 as "dialogClose", _5 as "dialogContent", _6 as "large", _7 as "medium", _8 as "no-padding", _9 as "noPadding", _a as "small", _b as "white" }
