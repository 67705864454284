import { Dispatch, SetStateAction, useState } from 'react';

export interface SetBooleanState extends Dispatch<SetStateAction<boolean>> {
    /**
     * Toggle the boolean value
     */
    toggle: () => void;

    /**
     * Set the boolean value to false
     */
    toFalse: () => void;

    /**
     * Set the boolean value to true
     */
    toTrue: () => void;
}

/**
 * Create a state for a boolean value with useful methods
 *
 * @param initial The initial value or factory function, defaults to false
 * @returns A pair of state and setState
 */
export default function useBooleanState(initial: boolean | (() => boolean) = false) {
    return toBooleanState(useState<boolean>(initial));
}

export function toBooleanState([state, setState]: [boolean, Dispatch<SetStateAction<boolean>>]): [boolean, SetBooleanState] {
    if (isInitialized(setState)) return [state, setState];

    assertInitialized(setState);

    setState.toggle = () => setState((x) => !x);
    setState.toFalse = () => setState(false);
    setState.toTrue = () => setState(true);

    return [state, setState];
}

function isInitialized(setState: Dispatch<SetStateAction<boolean>>): setState is SetBooleanState {
    return 'toggle' in setState;
}

function assertInitialized(setState: Dispatch<SetStateAction<boolean>>): asserts setState is SetBooleanState {}
