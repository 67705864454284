import { MouseEventHandler } from 'react';
import * as style from './SkipLinks.module.less';

const focusOnTarget: MouseEventHandler<HTMLAnchorElement> = (e) => {
    const elm = document.querySelector<HTMLElement>(e.currentTarget.getAttribute('href')!);
    if (elm) {
        elm.focus();
        e.preventDefault();
    }
};

export default function SkipLinks({ pageLabel }: { pageLabel?: 'search' | 'cart' | 'checkout' }) {
    return (
        <div className={style.skiplinks} aria-label="Hurtiglenker" role="region">
            <SkipLink href="#page" accessKey="s">
                Hopp til hovedinnhold
            </SkipLink>
            {pageLabel == 'search' ? (
                <>
                    <SkipLink href="#search-results">Hopp til produktutlisting</SkipLink>
                    <SkipLink href="#search-refinements">Hopp til filtreringsmuligheter</SkipLink>
                </>
            ) : pageLabel == 'cart' ? (
                <>
                    <SkipLink href="#delivery-method-section" accessKey="l">
                        Leveringsmåte
                    </SkipLink>
                    <SkipLink href="#cart-page-entries" accessKey="p">
                        Produkter i handlekurv
                    </SkipLink>
                    <SkipLink href="#nav-cart-summary" accessKey="o">
                        Oppsummering av handlekurv
                    </SkipLink>
                </>
            ) : pageLabel == 'checkout' ? (
                <>
                    <SkipLink href="#checkout-page-payment" accessKey="p">
                        Betalingsinformasjon
                    </SkipLink>
                    <SkipLink href="#cart-page-entries" accessKey="p">
                        Produkter i handlekurv
                    </SkipLink>
                    <SkipLink href="#nav-cart-summary" accessKey="o">
                        Oppsummering av handlekurv
                    </SkipLink>
                </>
            ) : (
                <>
                    <SkipLink href="#primary-navigation">Hopp til hovednavigasjon</SkipLink>
                    <SkipLink href="#site-footer">Hopp til bunnmeny med kontaktinformasjon</SkipLink>
                </>
            )}
        </div>
    );
}

interface SkipLink {
    children: string;
    href: string;
    accessKey?: string;
}

function SkipLink({ children, href, accessKey }: SkipLink) {
    return (
        <a onClick={focusOnTarget} className={style.link} href={href} accessKey={accessKey} key={href}>
            {children} {accessKey ? `[Accesskey '${accessKey}']` : ''}
        </a>
    );
}
