import { getAccessToken, handleUnauthorizedError } from '../utils/auth';

type Params = Record<string, string> | URLSearchParams;

export class ApiResponseError<E = any> extends Error {
    readonly status: number;
    readonly errors: E;
    constructor(status: number, error?: any) {
        super();
        this.errors = error?.errors ?? error;
        this.status = status;
    }
}

export class BadRequestError<E = any> extends ApiResponseError<E> {
    constructor(error: any) {
        super(400, error);
    }
}

export class UnauthorizedError<E = any> extends ApiResponseError<E> {
    constructor(error: any) {
        super(401, error);
    }
}

export class ForbiddenError<E = any> extends ApiResponseError<E> {
    constructor(error: any) {
        super(403, error);
    }
}

export class NotFoundError<E = any> extends ApiResponseError<E> {
    constructor(error: any) {
        super(404, error);
    }
}

async function getContent(response: Response): Promise<any> {
    if (response.headers.get('Content-Type')?.includes('application/json')) {
        return await response.json();
    } else if (response.headers.get('Content-Disposition')?.startsWith('attachment')) {
        const name = response.headers.get('Content-Disposition')?.match(/attachment; filename="(.*)"/)?.[1];
        const blob = await response.blob();
        return { blob, name };
    } else {
        return await response.text();
    }
}

async function getError(response: Response) {
    return response
        .json<any>()
        .then((e) => e?.errors ?? e)
        .catch(() => 'Det har oppstått en feil.');
}

async function handleStatusCode<T>(response: Response): Promise<T> {
    if (response.ok) {
        return await getContent(response);
    } else {
        switch (response.status) {
            case 400: // Bad Request
                throw new BadRequestError(await getError(response));
            case 401: // Unauthorized
                handleUnauthorizedError();
                throw new UnauthorizedError(await getError(response));
            case 403: // Forbidden
                throw new ForbiddenError(await getError(response));
            case 404: // Not Found
                throw new NotFoundError(await getError(response));
            case 429: // Too Many Requests
                const error = await getError(response);
                if (error?.cfWaitingRoom) location.reload();
                throw error;
            default:
                throw await getError(response);
        }
    }
}

export async function get<TResponse = any>(url: string, params?: Params, init?: RequestInit) {
    return fetch(params ? `${url}?${new URLSearchParams(params)}` : url, {
        ...init,
        headers: await getHeadersWithAuthorization(init?.headers),
    }).then<TResponse>(handleStatusCode);
}

export async function getAnonymously<TResponse = any>(url: string, params?: Params, init?: RequestInit) {
    return fetch(params ? `${url}?${new URLSearchParams(params)}` : url, {
        ...init,
        headers: getHeaders(init?.headers),
    }).then<TResponse>(handleStatusCode);
}

export async function httpDelete<TResponse = any>(url: string) {
    return fetch(url, {
        method: 'DELETE',
        headers: await getHeadersWithAuthorization(),
    }).then<TResponse>(handleStatusCode);
}

export { httpDelete as delete };

export async function patch<TResponse = any>(url: string) {
    return fetch(url, {
        method: 'PATCH',
        headers: await getHeadersWithAuthorization({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
    }).then<TResponse>(handleStatusCode);
}

export async function patchJson<TResponse = any>(url: string, datain?: any) {
    return fetch(url, {
        method: 'PATCH',
        headers: await getHeadersWithAuthorization({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(datain),
    }).then<TResponse>(handleStatusCode);
}

export async function post<TResponse = any>(url: string, datain?: any) {
    const params = new URLSearchParams(datain);

    return fetch(url, {
        method: 'POST',
        body: params,
        headers: await getHeadersWithAuthorization({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
    }).then<TResponse>(handleStatusCode);
}

export async function postJson<TResponse = any>(url: string, datain?: any) {
    return fetch(url, {
        method: 'POST',
        headers: await getHeadersWithAuthorization({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(datain),
    }).then<TResponse>(handleStatusCode);
}

export async function put<TResponse = any>(url: string, datain?: any) {
    const params = new URLSearchParams(datain);

    return fetch(url, {
        method: 'PUT',
        body: params,
        headers: await getHeadersWithAuthorization({ 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8' }),
    }).then<TResponse>(handleStatusCode);
}

export async function putJson<TResponse = any>(url: string, datain?: any) {
    return fetch(url, {
        method: 'PUT',
        headers: await getHeadersWithAuthorization({ 'Content-Type': 'application/json' }),
        body: JSON.stringify(datain),
    }).then<TResponse>(handleStatusCode);
}

function getHeaders(initial: HeadersInit = {}) {
    return new Headers({
        ...initial,
        Accept: 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    });
}

async function getHeadersWithAuthorization(initial: HeadersInit = {}) {
    const headers = getHeaders(initial);

    const accessToken = await getAccessToken().catch((e) => {
        console.error(e);

        // We might not need the access token for this request,
        // so we gracefully handle that it doesn't exist.
        // If it is required then the response will be 401
        // and the user will be forced to log in.
        return null;
    });

    if (accessToken) {
        headers.append('authorization', `Bearer ${accessToken}`);
    }

    return headers;
}
